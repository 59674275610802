import React, { useState, useEffect } from "react";
import qrCode from "../assets/images/donation/randomQRCode.png";
import DonationForm from "./DonationForm";

const EventCard = () => {
  const [showModal, setShowModal] = useState(false);

  const bankDetails = {
    organization: "Krishna Siksha Seva Sansthan",
    accountNumber: "3186404056",
    ifscCode: "CBIN0283129",
    bankName: "Central Bank of India",
    address: "Naini Industrial Area, Prayagraj",
  };

  return (
    <section className="events pt-0 pb-4">
      <div className="container">
        <div className="col-12">
          <style>
            {`
                .event-card {
                  transition: all 0.3s ease;
                  position: relative;
                  overflow: hidden;
                  border-radius: 15px !important;
                  margin-bottom: 20px;
                  border: none !important;
                }

                .event-card:hover {
                  transform: translateY(-5px);
                  box-shadow: 0 10px 20px rgba(0,0,0,0.2) !important;
                }

                .event-card::before {
                  content: '';
                  position: absolute;
                  top: 0;
                  left: 0;
                  width: 100%;
                  height: 100%;
                  background: rgba(255,255,255,0.1);
                  transform: translateX(-100%);
                  transition: transform 0.5s ease;
                }

                .event-card:hover::before {
                  transform: translateX(0);
                }

                .event-card * {
                  color: white !important;
                }

                .event-card .custom-btn-primary {
                  background: rgba(255, 255, 255, 0.2);
                  border: 2px solid white;
                  transition: all 0.3s ease;
                }

                .event-card .custom-btn-primary:hover {
                  background: white;
                  color: #333 !important;
                }

                .bank-details {
                  background: rgba(255, 255, 255, 0.15);
                  border-radius: 10px;
                  padding: 15px;
                  margin-top: 15px;
                }

                .qr-code {
                  max-width: 120px;
                  height: auto;
                  margin: 10px auto;
                }

                .bank-info {
                  font-size: 0.9rem;
                  margin-bottom: 5px;
                }

                .bank-title {
                  font-size: 1.1rem;
                  font-weight: bold;
                  margin-bottom: 10px;
                }

                .qr-title {
                  font-size: 1rem;
                  font-weight: 600;
                  color: white;
                  margin-bottom: 10px;
                }

                .modal {
                  transition: opacity 0.3s ease-in-out;
                  opacity: 0;
                }

                .modal.show {
                  opacity: 1;
                }

                .modal-dialog {
                  transform: translateY(-50px);
                  transition: transform 0.3s ease-out;
                }

                .modal.show .modal-dialog {
                  transform: translateY(0);
                }

                .modal-backdrop {
                  transition: opacity 0.3s ease-in-out;
                  opacity: 0;
                }

                .modal-backdrop.show {
                  opacity: 0.5;
                }
              `}
          </style>

          <div
            className="col box-wrap rounded-4 shadow-lg event-card bank-details"
            style={{ background: "linear-gradient(45deg, #4776E6, #8E54E9)" }}
          >
            <div className="row g-4 align-items-center">
              {/* Left Column */}
              <div className="col-lg-5 text-center col-md-6">
                <h5 className="qr-title mb-3 text-lg-start text-md-start text-center ms-lg-5">
                  Scan QR to Donate
                </h5>
                <div className="d-flex flex-column flex-md-row align-items-center justify-content-center justify-content-md-start">
                  <img src={qrCode} alt="Payment QR Code" className="qr-code img-fluid rounded-3 img-thumbnail" />
                  <button
                    className="btn custom-btn-primary mt-3 mt-md-0 ms-md-3 ms-lg-5"
                    onClick={() => setShowModal(true)}
                  >
                    Donate Now
                  </button>
                </div>
              </div>

              {/* Right Column */}
              <div className=" col-lg-7 text-center col-md-6">
                <h4 className="bank-title">{bankDetails.organization}</h4>
                <p className="bank-info">Bank: {bankDetails.bankName}</p>
                <p className="bank-info">
                  Account No: {bankDetails.accountNumber}
                </p>
                <p className="bank-info">IFSC Code: {bankDetails.ifscCode}</p>
                <p className="bank-info">Address: {bankDetails.address}</p>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Add Modal */}
      <div
        className={`modal fade ${showModal ? "show" : ""}`}
        style={{ display: showModal ? "block" : "none" }}
        tabIndex="-1"
        aria-hidden={!showModal}
      >
        <div className="modal-dialog modal-lg modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title text-dark">Make a Donation</h5>
              <button
                type="button"
                className="btn-close"
                onClick={() => setShowModal(false)}
              ></button>
            </div>
            <div className="modal-body p">
              <DonationForm title="" />
            </div>
          </div>
        </div>
      </div>

      {/* Add Modal Backdrop */}
      {showModal && (
        <div
          className="modal-backdrop fade show"
          onClick={() => setShowModal(false)}
        ></div>
      )}
    </section>
  );
};

export default EventCard;
