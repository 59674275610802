export const deityImages = [
  {
    id: 1,
    src: require('../assets/images/sansthan/image1 (1).jpeg'),
    caption: "श्री राधा कृष्ण मंदिर दर्शन",
  },
  {
    id: 2,
    src: require('../assets/images/sansthan/image1 (2).jpeg'),
    caption: "श्री हनुमान जी का मंदिर",
  },
  {
    id: 3,
    src: require('../assets/images/sansthan/image1 (3).jpeg'),
    caption: "श्री गणेश जी का मंदिर",
  },
  {
    id: 4,
    src: require('../assets/images/sansthan/image1 (4).jpeg'),
    caption: "मंदिर का मुख्य द्वार",
  },


  {
    id: 5,  
    src: require('../assets/images/sansthan/image1 (5).jpeg'),
    caption: "मंदिर का मुख्य द्वार",
  },


  {
    id: 6,
    src: require('../assets/images/sansthan/image1 (6).jpeg'),
    caption: "मंदिर का मुख्य द्वार",
  },
  
{
    id: 7,
    src: require('../assets/images/sansthan/image1 (7).jpeg'),
    caption: "मंदिर का मुख्य द्वार",
  },

  {
    id: 8,
    src: require('../assets/images/sansthan/image1 (8).jpeg'),
    caption: "मंदिर का मुख्य द्वार",
  },

{
    id: 9,
    src: require('../assets/images/sansthan/image1 (9).jpeg'),
    caption: "मंदिर का मुख्य द्वार",
  },

  {
    id: 10,
    src: require('../assets/images/sansthan/image1 (10).jpeg'),
    caption: "मंदिर का मुख्य द्वार",
  },

  {
    id: 11,
    src: require('../assets/images/sansthan/image1 (11).jpeg'),
    caption: "मंदिर का मुख्य द्वार",
  },

  {
    id: 12,
    src: require('../assets/images/sansthan/image1 (12).jpeg'),
    caption: "मंदिर का मुख्य द्वार",
  },

  {
    id: 13,
    src: require('../assets/images/sansthan/image1 (13).jpeg'),
    caption: "मंदिर का मुख्य द्वार",
  },

  {
    id: 14,
    src: require('../assets/images/sansthan/image1 (14).jpeg'),
    caption: "मंदिर का मुख्य द्वार",
  },

  {
    id: 15,
    src: require('../assets/images/sansthan/image1 (15).jpeg'),
    caption: "मंदिर का मुख्य द्वार",
  },

  {
    id: 16,
    src: require('../assets/images/sansthan/image1 (16).jpeg'),
    caption: "मंदिर का मुख्य द्वार",
  },

  {
    id: 17,
    src: require('../assets/images/sansthan/image1 (17).jpeg'),
    caption: "मंदिर का मुख्य द्वार",
  },

  {
    id: 18,
    src: require('../assets/images/sansthan/image1 (18).jpeg'),
    caption: "मंदिर का मुख्य द्वार",
  },

  {
    id: 19,
    src: require('../assets/images/sansthan/image1 (19).jpeg'),
    caption: "मंदिर का मुख्य द्वार",
  },

  {
    id: 20,
    src: require('../assets/images/sansthan/image1 (20).jpeg'),
    caption: "मंदिर का मुख्य द्वार",
  },

  {
    id: 21,
    src: require('../assets/images/sansthan/image1 (21).jpeg'),
    caption: "मंदिर परिसर का दृश्य",
  },

{
    id: 22,
    src: require('../assets/images/sansthan/image1 (22).jpeg'),
    caption: "मंदिर परिसर का दृश्य",
  },

{
    id: 23,
    src: require('../assets/images/sansthan/image1 (23).jpeg'),
    caption: "मंदिर परिसर का दृश्य",
  },

  {
    id: 24,
    src: require('../assets/images/sansthan/image1 (24).jpeg'),
    caption: "मंदिर परिसर का दृश्य",
  },

  {
    id: 25,
    src: require('../assets/images/sansthan/image1 (25).jpeg'),
    caption: "मंदिर परिसर का दृश्य",
  },

  {
    id: 26,
    src: require('../assets/images/sansthan/image1 (26).jpeg'),
    caption: "मंदिर परिसर का दृश्य",
  },
  {
    id: 27,
    src: require('../assets/images/sansthan/image1 (27).jpeg'),
    caption: "मंदिर परिसर का दृश्य",
  },

  {
    id: 28,
    src: require('../assets/images/sansthan/image1 (28).jpeg'),
    caption: "मंदिर परिसर का दृश्य",
  },

  {
    id: 29,
    src: require('../assets/images/sansthan/image1 (29).jpeg'),
    caption: "मंदिर परिसर का दृश्य",
  },

  {
    id: 30,
    src: require('../assets/images/sansthan/image1 (30).jpeg'),
    caption: "मंदिर परिसर का दृश्य",
  },

{
    id: 31,
    src: require('../assets/images/sansthan/image1 (31).jpeg'),
    caption: "मंदिर परिसर का दृश्य",
  },

  {
    id: 32,
    src: require('../assets/images/sansthan/image1 (32).jpeg'),
    caption: "मंदिर परिसर का दृश्य",
  },

  {
    id: 33,
    src: require('../assets/images/sansthan/image1 (33).jpeg'),
    caption: "मंदिर परिसर का दृश्य",
  },

  {
    id: 34,
    src: require('../assets/images/sansthan/image1 (34).jpeg'),
    caption: "मंदिर परिसर का दृश्य",
  },

  {
    id: 35,
    src: require('../assets/images/sansthan/image1 (35).jpeg'),
    caption: "मंदिर परिसर का दृश्य",
  },

  {
    id: 36,
    src: require('../assets/images/sansthan/image1 (36).jpeg'),
    caption: "मंदिर परिसर का दृश्य",
  },

  {
    id: 37,
    src: require('../assets/images/sansthan/image1 (37).jpeg'),
    caption: "मंदिर परिसर का दृश्य",
  },

  {
    id: 38,
    src: require('../assets/images/sansthan/image1 (38).jpeg'),
    caption: "मंदिर परिसर का दृश्य",
  },

  {
    id: 39,
    src: require('../assets/images/sansthan/image1 (39).jpeg'),
    caption: "मंदिर परिसर का दृश्य",
  },

  {
    id: 40,
    src: require('../assets/images/sansthan/image1 (40).jpeg'),
    caption: "मंदिर परिसर का दृश्य",
  },

  {
    id: 41,
    src: require('../assets/images/sansthan/image1 (41).jpeg'),
    caption: "मंदिर परिसर का दृश्य",
  },

  {
    id: 42,
    src: require('../assets/images/sansthan/image1 (42).jpeg'),
    caption: "मंदिर परिसर का दृश्य",
  },

  {
    id: 43,
    src: require('../assets/images/sansthan/image1 (43).jpeg'),
    caption: "मंदिर परिसर का दृश्य",
  },

  {
    id: 44,
    src: require('../assets/images/sansthan/image1 (44).jpeg'),
    caption: "मंदिर परिसर का दृश्य",
  },

  {
    id: 45,
    src: require('../assets/images/sansthan/image1 (45).jpeg'),
    caption: "मंदिर परिसर का दृश्य",
  },

  {
    id: 46,
    src: require('../assets/images/sansthan/image1 (46).jpeg'),
    caption: "मंदिर परिसर का दृश्य",
  },

  {
    id: 47,
    src: require('../assets/images/sansthan/image1 (47).jpeg'),
    caption: "मंदिर परिसर का दृश्य",
  },

  {
    id: 48,
    src: require('../assets/images/sansthan/image1 (48).jpeg'),
    caption: "मंदिर परिसर का दृश्य",
  },

  {
    id: 49,
    src: require('../assets/images/sansthan/image1 (49).jpeg'),
    caption: "मंदिर परिसर का दृश्य",
  },

  {
    id: 50,
    src: require('../assets/images/sansthan/image1 (50).jpeg'),
    caption: "मंदिर परिसर का दृश्य",
  },

  {
    id: 51,
    src: require('../assets/images/sansthan/image1 (51).jpeg'),
    caption: "मंदिर परिसर का दृश्य",
  },

  {
    id: 52,
    src: require('../assets/images/sansthan/image1 (52).jpeg'),
    caption: "मंदिर परिसर का दृश्य",
  },

  {
    id: 53,
    src: require('../assets/images/sansthan/image1 (53).jpeg'),
    caption: "मंदिर परिसर का दृश्य",
  },

  {
    id: 54,
    src: require('../assets/images/sansthan/image1 (54).jpeg'),
    caption: "मंदिर परिसर का दृश्य",
  },

  {
    id: 55,
    src: require('../assets/images/sansthan/image1 (55).jpeg'),
    caption: "मंदिर परिसर का दृश्य",
  },

  {
    id: 56,
    src: require('../assets/images/sansthan/image1 (56).jpeg'),
    caption: "मंदिर परिसर का दृश्य",
  },

  {
    id: 57,
    src: require('../assets/images/sansthan/image1 (57).jpeg'),
    caption: "मंदिर परिसर का दृश्य",
  },


  {
    id: 58,
    src: require('../assets/images/sansthan/image1 (58).jpeg'),
    caption: "मंदिर परिसर का दृश्य",
  },

  {
    id: 59,
    src: require('../assets/images/sansthan/image1 (59).jpeg'),
    caption: "मंदिर परिसर का दृश्य",
  },

  {
    id: 60,
    src: require('../assets/images/sansthan/image1 (60).jpeg'),
    caption: "मंदिर परिसर का दृश्य",
  },

  {
    id: 61,
    src: require('../assets/images/sansthan/image1 (61).jpeg'),
    caption: "मंदिर परिसर का दृश्य",
  },

  {
    id: 62,
    src: require('../assets/images/sansthan/image1 (62).jpeg'),
    caption: "मंदिर परिसर का दृश्य",
  },

  {
    id: 63,
    src: require('../assets/images/sansthan/image1 (63).jpeg'),
    caption: "मंदिर परिसर का दृश्य",
  },

  {
    id: 64,
    src: require('../assets/images/sansthan/image1 (64).jpeg'),
    caption: "मंदिर परिसर का दृश्य",
  },

  {
    id: 65,
    src: require('../assets/images/sansthan/image1 (65).jpeg'),
    caption: "मंदिर परिसर का दृश्य",
  },

  {
    id: 66,
    src: require('../assets/images/sansthan/image1 (66).jpeg'),
    caption: "मंदिर परिसर का दृश्य",
  },

  {
    id: 67,
    src: require('../assets/images/sansthan/image1 (67).jpeg'),
    caption: "मंदिर परिसर का दृश्य",
  },

  {
    id: 68,
    src: require('../assets/images/sansthan/image1 (68).jpeg'),
    caption: "मंदिर परिसर का दृश्य",
  },

  {
    id: 69,
    src: require('../assets/images/sansthan/image1 (69).jpeg'),
    caption: "मंदिर परिसर का दृश्य",
  },

  {
    id: 70,
    src: require('../assets/images/sansthan/image1 (70).jpeg'),
    caption: "मंदिर परिसर का दृश्य",
  },

  {
    id: 71,
    src: require('../assets/images/sansthan/image1 (71).jpeg'),
    caption: "मंदिर परिसर का दृश्य",
  },

  {
    id: 72,
    src: require('../assets/images/sansthan/image1 (72).jpeg'),
    caption: "मंदिर परिसर का दृश्य",
  },

  {
    id: 73,
    src: require('../assets/images/sansthan/image1 (73).jpeg'),
    caption: "मंदिर परिसर का दृश्य",
  },

  {
    id: 74,
    src: require('../assets/images/sansthan/image1 (74).jpeg'),
    caption: "मंदिर परिसर का दृश्य",
  },

  {
    id: 75,
    src: require('../assets/images/sansthan/image1 (75).jpeg'),
    caption: "मंदिर परिसर का दृश्य",
  },

  {
    id: 76,
    src: require('../assets/images/sansthan/image1 (76).jpeg'),
    caption: "मंदिर परिसर का दृश्य",
  },

  {
    id: 77,
    src: require('../assets/images/sansthan/image1 (77).jpeg'),
    caption: "मंदिर परिसर का दृश्य",
  },

  {
    id: 78,
    src: require('../assets/images/sansthan/image1 (78).jpeg'),
    caption: "मंदिर परिसर का दृश्य",
  },

  {
    id: 79,
    src: require('../assets/images/sansthan/image1 (79).jpeg'),
    caption: "मंदिर परिसर का दृश्य",
  },

  {
    id: 80,
    src: require('../assets/images/sansthan/image1 (80).jpeg'),
    caption: "मंदिर परिसर का दृश्य",
  },

  {
    id: 81,
    src: require('../assets/images/sansthan/image1 (81).jpeg'),
    caption: "मंदिर परिसर का दृश्य",
  },

  {
    id: 82,
    src: require('../assets/images/sansthan/image1 (82).jpeg'),
    caption: "मंदिर परिसर का दृश्य",
  },

  {
    id: 83,
    src: require('../assets/images/sansthan/image1 (83).jpeg'),
    caption: "मंदिर परिसर का दृश्य",
  },


  {
    id: 84,
    src: require('../assets/images/sansthan/image1 (84).jpeg'),
    caption: "मंदिर परिसर का दृश्य",
  },

  {
    id: 85,
    src: require('../assets/images/sansthan/image1 (85).jpeg'),
    caption: "मंदिर परिसर का दृश्य",
  },

  {
    id: 86,
    src: require('../assets/images/sansthan/image1 (86).jpeg'),
    caption: "मंदिर परिसर का दृश्य",
  },

  {
    id: 87,
    src: require('../assets/images/sansthan/image1 (87).jpeg'),
    caption: "मंदिर परिसर का दृश्य",
  },

  {
    id: 88,
    src: require('../assets/images/sansthan/image1 (88).jpeg'),
    caption: "मंदिर परिसर का दृश्य",
  },

  {
    id: 89,
    src: require('../assets/images/sansthan/image1 (89).jpeg'),
    caption: "मंदिर परिसर का दृश्य",
  },

  {
    id: 90,
    src: require('../assets/images/sansthan/image1 (90).jpeg'),
    caption: "मंदिर परिसर का दृश्य",
  },

  {
    id: 91,
    src: require('../assets/images/sansthan/image1 (91).jpeg'),
    caption: "मंदिर परिसर का दृश्य",
  },

  {
    id: 92,
    src: require('../assets/images/sansthan/image1 (92).jpeg'),
    caption: "मंदिर परिसर का दृश्य",
  },

  {
    id: 93,
    src: require('../assets/images/sansthan/image1 (93).jpeg'),
    caption: "मंदिर परिसर का दृश्य",
  },

  {
    id: 94,
    src: require('../assets/images/sansthan/image1 (94).jpeg'),
    caption: "मंदिर परिसर का दृश्य",
  },

  {
    id: 95,
    src: require('../assets/images/sansthan/image1 (95).jpeg'),
    caption: "मंदिर परिसर का दृश्य",
  },

];
