import React from "react";
import Header from "./Header";
import Footer from "./Footer";
import EventCard from "./EventCard";
import Meta from "./Meta";
import DonationForm from "./DonationForm";
import goseva1 from "../assets/images/donation/goseva1.jpg";
import goseva2 from "../assets/images/donation/goseva2.jpg";
const GoSeva = () => {
  return (
    <>
      <Meta />
      <Header />

      <>
        <div className="container">
          <img
            src="https://donations.iskconbangalore.org/go-seva/assets/img/gau-poshana-desktop-banner-images.jpg"
            className="img-fluid rounded-4 img-thumbnail"
            alt=""
          />
          <EventCard />
        </div>

        <div className="container">
          <div className="row g-4">
            <div className="col-lg-6 col-md-12">
              <div className="card border border-warning rounded-4 h-100">
                <div className="card-body">
                  <img
                    src={goseva1}
                    alt=""
                    className="img-fluid rounded-4"
                  />
                </div>
              </div>
            </div>

            <div className="col-lg-6 col-md-12">
              <div className="card border border-warning rounded-4 h-100">
                <div className="card-body mt-5">
                  <p className="card-text mb-4">
                    As an offering of devotion to Lord Krishna and inspired by
                    Srila Prabhupada, we at ISKCON Bangalore are tending to 90
                    cows. We provide them fresh grass, fodder, water, shelter,
                    and care for them like our mothers.
                  </p>
                  <h5 className="card-title">Offer Gau Poshana Seva</h5>
                  <p className="card-text mt-3">
                    Gau Seva is considered as one of the most revered services.
                    Join us in our service for this humble being. Offer seva
                    towards feeding the sacred cows and receive eternal
                    blessings from Lord Krishna.
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className="row g-4 mt-4">
            <div className="col-lg-6 col-md-12">
              <div className="card border border-warning rounded-4 h-100">
                <div className="card-body">
                  <div className="row align-items-center">
                    <div className="col-lg-6">
                      <img
                        src="https://donations.iskconbangalore.org/go-seva/assets/img/krishna.png"
                        className="img-fluid "
                        alt="middle-banner-img"
                      />
                    </div>
                    <div className="col-lg-6">
                      <h5 className="card-title">
                        Lord Krishna is described as
                      </h5>
                      <p className="card-text mt-3">
                        <b>"namo brahmanya devaya go-brahmana hitaya ca"</b>
                        <br />
                        <br />
                        (Vishnu Purana 1.19.65), the well-wisher of cows. This
                        was well-reflected in His care for cows as a cowherd boy
                        in Vrindavana. In fact, Lord Krishna's other names,
                        Govinda and Gopala mean, "friend and protector of cows".
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-lg-6 col-md-12">
              <div className="card border border-warning rounded-4 h-100">
                <div className="card-body">
                  <img
                    src={goseva2}
                    alt=""
                    className="img-fluid rounded-4 "
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <section className="care-section mt-md-5 mt-4 mb-md-5 mb-3">
          <div className="container pl-lg-5 pl-md-2 pr-lg-5 pr-md-2">
            <div className="row">
              <div className="col-md-12 text-center mb-3">
                <h3 className="care-title">Our Gaushala at a glance</h3>
              </div>

              {/* First Card */}
              <div className="col-md-6 mt-3">
                <div className="card border rounded-4 border-warning h-100">
                  <div className="row g-0">
                    <div className="col-md-6 text-center">
                      <img
                        src="https://donations.iskconbangalore.org/go-seva/assets/img/care-one.png"
                        alt="care-img"
                        className="img-fluid care-img"
                      />
                    </div>
                    <div className="col-md-6 p-3">
                      <h5>Care:</h5>
                      <p className="mt-3">
                        Daily cow care, medical care, once a month routine
                        check-up, emergency care
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              {/* Second Card */}
              <div className="col-md-6 mt-3">
                <div className="card border rounded-4 border-warning h-100">
                  <div className="row g-0">
                    <div className="col-md-6 text-center">
                      <img
                        src="https://donations.iskconbangalore.org/go-seva/assets/img/care-two.png"
                        alt="care-img"
                        className="img-fluid care-img"
                      />
                    </div>
                    <div className="col-md-6 p-3">
                      <h5>Facilities for the cows:</h5>
                      <p className="mt-3">
                        Clean, hygienic indoor &amp; outdoor sheds, gutters for
                        proper cleanliness, 24 hours clean water connection,
                        large grazing field, lush green trees for cool breeze
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </>

      <Footer />
    </>
  );
};

export default GoSeva;
