import React, { useState } from "react";

const DonationForm = ({
  title,
  predefinedAmounts = [1000, 2000, 4000, 5000, 10000, 20000],
}) => {
  // Form state
  const [amount, setAmount] = useState(predefinedAmounts[0]);
  const [customAmount, setCustomAmount] = useState("");
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    addressLine1: "",
    addressLine2: "",
    city: "",
    zipCode: "",
  });
  const [errors, setErrors] = useState({});

  // Enhanced validation functions
  const validateName = (name) => {
    // Only letters, spaces, and basic punctuation
    return /^[a-zA-Z\s'.,-]{2,50}$/.test(name.trim());
  };

  const validateEmail = (email) => {
    // Stricter email validation
    return /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/.test(email);
  };

  const validatePhone = (phone) => {
    // Exactly 10 digits, no other characters
    return /^[0-9]{10}$/.test(phone);
  };

  const validateZipCode = (zipCode) => {
    // Exactly 6 digits, no other characters
    return /^[0-9]{6}$/.test(zipCode);
  };

  const validateCity = (city) => {
    // Only letters, spaces, and basic punctuation
    return /^[a-zA-Z\s'.,-]{2,50}$/.test(city.trim());
  };

  const validateAddress = (address) => {
    // Allow alphanumeric, spaces, and basic punctuation
    return /^[a-zA-Z0-9\s,.-]{5,100}$/.test(address.trim());
  };

  // Enhanced input handlers
  const handleInputChange = (field, value) => {
    let processedValue = value;

    // Input processing based on field type
    switch (field) {
      case 'phone':
        // Remove any non-digit characters
        processedValue = value.replace(/\D/g, '').slice(0, 10);
        break;
      case 'zipCode':
        // Remove any non-digit characters
        processedValue = value.replace(/\D/g, '').slice(0, 6);
        break;
      case 'name':
      case 'city':
        // Remove numbers and special characters except allowed ones
        processedValue = value.replace(/[^a-zA-Z\s'.,()-]/g, '');
        break;
      default:
        processedValue = value;
    }

    setFormData({ ...formData, [field]: processedValue });
    
    // Clear error when user starts typing
    if (errors[field]) {
      setErrors({ ...errors, [field]: '' });
    }
  };

  // Enhanced form submission
  const handleSubmit = (e) => {
    e.preventDefault();
    const newErrors = {};

    // Amount validation
    const finalAmount = customAmount || amount;
    if (!finalAmount || finalAmount <= 0) {
      newErrors.amount = "Please enter a valid amount greater than 0";
    }
    if (customAmount && (isNaN(customAmount) || Number(customAmount) <= 0)) {
      newErrors.amount = "Please enter a valid amount";
    }

    // Name validation
    if (!formData.name.trim()) {
      newErrors.name = "Name is required";
    } else if (!validateName(formData.name)) {
      newErrors.name = "Please enter a valid name (letters only)";
    }

    // Email validation
    if (!formData.email.trim()) {
      newErrors.email = "Email is required";
    } else if (!validateEmail(formData.email)) {
      newErrors.email = "Please enter a valid email address";
    }

    // Phone validation
    if (!formData.phone) {
      newErrors.phone = "Phone number is required";
    } else if (!validatePhone(formData.phone)) {
      newErrors.phone = "Please enter a valid 10-digit phone number";
    }

    // Address validation
    if (!formData.addressLine1.trim()) {
      newErrors.addressLine1 = "Address is required";
    } else if (!validateAddress(formData.addressLine1)) {
      newErrors.addressLine1 = "Please enter a valid address";
    }

    // City validation
    if (!formData.city.trim()) {
      newErrors.city = "City is required";
    } else if (!validateCity(formData.city)) {
      newErrors.city = "Please enter a valid city name";
    }

    // ZIP code validation
    if (!formData.zipCode) {
      newErrors.zipCode = "ZIP code is required";
    } else if (!validateZipCode(formData.zipCode)) {
      newErrors.zipCode = "Please enter a valid 6-digit ZIP code";
    }

    setErrors(newErrors);

    // If no errors, proceed with form submission
    if (Object.keys(newErrors).length === 0) {
      console.log("Form submitted:", {
        amount: finalAmount,
        ...formData,
      });
      // Show success alert
      alert(`Thank you for your donation of ₹${finalAmount}! We'll contact you shortly.`);
      
      // Optional: Reset form
      setFormData({
        name: "",
        email: "",
        phone: "",
        addressLine1: "",
        addressLine2: "",
        city: "",
        zipCode: "",
      });
      setAmount(predefinedAmounts[0]);
      setCustomAmount("");
    }
  };

  const handleAmountSelect = (value) => {
    setAmount(value);
    setCustomAmount("");
  };

  return (
    <form onSubmit={handleSubmit} className="donation-form">
      {title && <h2 className="mb-4">{title}</h2>}

      {/* Amount Section */}
      <div className="amount-section mb-4">
        <div className="selected-amount mb-3">
          <span className="currency">₹</span>
          <span className="amount">{customAmount || amount}.00</span>
        </div>

        <div className="amount-options d-flex flex-wrap gap-2">
          {predefinedAmounts.map((value) => (
            <button
              type="button"
              key={value}
              className={`btn ${amount === value ? "btn-warning" : "btn-outline-warning"}`}
              onClick={() => handleAmountSelect(value)}
            >
              {value}
            </button>
          ))}
          <input
            type="number"
            className={`form-control ${errors.amount ? "is-invalid" : ""}`}
            placeholder="Custom Amount"
            value={customAmount}
            onChange={(e) => {
              setCustomAmount(e.target.value);
              setAmount(0);
            }}
          />
          {errors.amount && <div className="invalid-feedback">{errors.amount}</div>}
        </div>
      </div>

      {/* Personal Info Section */}
      <div className="personal-info mb-4">
        <h4>Personal Info</h4>
        <div className="row g-3">
          <div className="col-md-6">
            <input
              type="text"
              className={`form-control ${errors.name ? "is-invalid" : ""}`}
              placeholder="Name *"
              value={formData.name}
              onChange={(e) => handleInputChange("name", e.target.value)}
            />
            {errors.name && <div className="invalid-feedback">{errors.name}</div>}
          </div>

          <div className="col-md-6">
            <input
              type="email"
              className={`form-control ${errors.email ? "is-invalid" : ""}`}
              placeholder="Email Address *"
              value={formData.email}
              onChange={(e) => handleInputChange("email", e.target.value)}
            />
            {errors.email && <div className="invalid-feedback">{errors.email}</div>}
          </div>

          <div className="col-md-6">
            <input
              type="tel"
              className={`form-control ${errors.phone ? "is-invalid" : ""}`}
              placeholder="Phone *"
              value={formData.phone}
              onChange={(e) => handleInputChange("phone", e.target.value)}
              maxLength={10}
            />
            {errors.phone && <div className="invalid-feedback">{errors.phone}</div>}
          </div>
        </div>
      </div>

      {/* Billing Details Section */}
      <div className="billing-details mb-4">
        <h4>Billing Details</h4>
        <div className="row g-3">
          <div className="col-lg-6">
            <input
              type="text"
              className={`form-control ${errors.addressLine1 ? "is-invalid" : ""}`}
              placeholder="Address Line 1 *"
              value={formData.addressLine1}
              onChange={(e) => handleInputChange("addressLine1", e.target.value)}
            />
            {errors.addressLine1 && <div className="invalid-feedback">{errors.addressLine1}</div>}
          </div>

          <div className="col-lg-6">
            <input
              type="text"
              className="form-control"
              placeholder="Address Line 2"
              value={formData.addressLine2}
              onChange={(e) => handleInputChange("addressLine2", e.target.value)}
            />
          </div>

          <div className="col-lg-6">
            <input
              type="text"
              className={`form-control ${errors.city ? "is-invalid" : ""}`}
              placeholder="City *"
              value={formData.city}
              onChange={(e) => handleInputChange("city", e.target.value)}
            />
            {errors.city && <div className="invalid-feedback">{errors.city}</div>}
          </div>

          <div className="col-lg-6">
            <input
              type="text"
              className={`form-control ${errors.zipCode ? "is-invalid" : ""}`}
              placeholder="Zip / Postal Code *"
              value={formData.zipCode}
              onChange={(e) => handleInputChange("zipCode", e.target.value)}
            />
            {errors.zipCode && <div className="invalid-feedback">{errors.zipCode}</div>}
          </div>
        </div>
      </div>

      <div className="d-flex justify-content-end">
        <button type="submit" className="btn btn-success py-2" style={{ width: "200px" }}>
          DONATE NOW
        </button>
      </div>

      
    </form>
  );
};

export default DonationForm;
